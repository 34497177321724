import styled from '@emotion/styled';
import { Box } from 'rebass';
import Image from '../Image'
import { colors, space } from '../../styles/theme';

export const CardContent = styled(Box)({
  position: 'absolute',
  width: '100%',
  bottom: '100%',
  height: '100%',
  transition: 'all 0.3s ease-in-out',
  zIndex: 20,

  background: colors.offwhite,
  color: colors.offblack,
  padding: `${space.large} ${space.base}`,
});

export const CardIcons = styled(Box)({
  position: 'relative',
  marginBottom: space.base,

  '& i': {
    fontSize: '1.6rem',
    color: colors.offblack,
    marginRight: space.base,
  }
});

export const CardImage = styled(Image)({
  position: 'relative',
  transition: 'all 0.3s ease-in-out',
  zIndex: 10,
});

export const CardTitle = styled('h2')({
  position: 'absolute',
  zIndex: 2,
  width: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  transition: 'all 0.3s ease-in-out',
  textAlign: 'center',
  margin: 0,
  lineHeight: 1.4,
  color: colors.white,
  padding: space.base,
});

export const CardWrapper = styled('div')({
  position: 'relative',
  marginBottom: space.base,
  transition: 'all 0.3s ease-in-out',
  overflow: 'hidden',

  '&:hover': {
    boxShadow: 'rgba(0, 0, 0, 0.4) 0px 4px 8px 0px',
    transform: 'scale(1.05)',

    [`& ${CardContent}`] : {
      bottom: 0,
    }
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: colors.secondary,
    opacity: 0.3,
    transition: 'all 0.3s ease-in-out',
    pointerEvents: 'none',
  },

  '&:hover::after': {
    opacity: 0.7,
  }
});
