import React from 'react';
import PropTypes from 'prop-types';
import { IconLink, Wrapper } from './Contact.style';

const Index = ({ facebook, instagram, linkedin, youtube }) => {
  return (
    <Wrapper>
      {linkedin && (
        <IconLink href={linkedin} target="_blank" rel="noopener">
          <i className="fab fa-linkedin"/>
        </IconLink>
      )}
      {instagram && (
        <IconLink href={instagram} target="_blank" rel="noopener">
          <i className="fab fa-instagram"/>
        </IconLink>
      )}
      {youtube && (
        <IconLink href={youtube} target="_blank" rel="noopener">
          <i className="fab fa-youtube-square"/>
        </IconLink>
      )}
      {facebook && (
        <IconLink href={facebook} target="_blank" rel="noopener">
          <i className="fab fa-facebook-square"/>
        </IconLink>
      )}
    </Wrapper>
  )
};

Index.propTypes = {
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  linkedin: PropTypes.string,
  youtube: PropTypes.string,
};

Index.defaultProps = {
  facebook: '',
  instagram: '',
  linkedin: '',
  youtube: '',
};

export default Index;