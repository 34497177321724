import React from 'react';
import { Grid, Column } from '../Layout';
import HtmlRenderer from '../HtmlRenderer';
import PropTypes from "prop-types";
import Image from '../Image'
import { BlockButton, Subtitle } from './ImageBlock.style';

const ImageBlock = ({
  ctalabel,
  ctaurl,
  id,
  image,
  subtitle,
  title
}) => {
  return (
    <div id={id}>
      <Grid>
        <Column width={[12, 12, 6, 6]}>
          <h1><HtmlRenderer html={title} /></h1>
          <Subtitle><HtmlRenderer html={subtitle} /></Subtitle>

          {ctalabel && (
            <BlockButton url={ctaurl}>{ctalabel}</BlockButton>
          )}
        </Column>

        {image && (
          <Column width={[0, 0, 6, 6]}>
            <Image img={image} />
          </Column>
        )}
      </Grid>
    </div>
  )
};

ImageBlock.propTypes = {
  ctalabel: PropTypes.string,
  ctaurl: PropTypes.string,
  id: PropTypes.string.isRequired,
  image: PropTypes.shape({
    sourceUrl: PropTypes.string,
  }),
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

ImageBlock.defaultProps = {
  ctalabel: '',
  ctaurl: '/',
  image: null,
  subtitle: '',
  title: '',
};

export default ImageBlock;