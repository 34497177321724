import React from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  CardIcons,
  CardImage,
  CardTitle,
  CardWrapper,
} from './Card.style'

const Card = ({ description, icons, image, title }) => {
  return (
    <CardWrapper>
      <CardContent>
        {icons.length > 0 && (
          <CardIcons>
            {icons.map(icon => (
              <i className={`fab ${icon}`}/>
            ))}
          </CardIcons>
        )}
        {description && <span>{description}</span>}
      </CardContent>

      {image && (
        <CardImage img={image}/>
      )}
      <CardTitle>{title}</CardTitle>
    </CardWrapper>
  )
};

Card.propTypes = {
  description: PropTypes.string,
  icons: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.object,
  link: PropTypes.string,
  title: PropTypes.string,
};

Card.defaultProps = {
  description: '',
  icons: [],
  image: null,
  link: '/',
  title: '',
};


export default Card;