import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '.';
import { Grid, Column, Stack } from '../components/Layout';
import ImageBlock from '../components/ImageBlock';
import Card from '../components/Card';
import Contact from '../components/Contact';
import Section from '../components/Section';
import HtmlRenderer from '../components/HtmlRenderer';
import SEO from '../components/SEO';

const handleContact = (section, index) => {
  return (
    <Section
      id={section.id}
      key={section.id}
      textAlign="center"
      title={section.title}
      variant={index % 2 === 0 ? 'primary' : 'secondary'}
    >
      <Contact {...section} />
    </Section>
  )
};

const handleImageBlock = (section, index) => {
  return (
    <Section key={section.id} variant={index % 2 === 0 ? 'primary' : 'secondary'} id={section.id}>
      <ImageBlock {...section} />
    </Section>
  )
};

const handleTextBlock = (section, index) => {
  const { title, content, id } = section;
  return (
    <Section key={id} title={title} variant={index % 2 === 0 ? 'primary' : 'secondary'} id={id}>
      <HtmlRenderer html={content} />
    </Section>
  )
};

const handleFeaturedProjects = (section, index) => {
  const { title, subtitle, projects } = section;
  const items = projects && projects.map(project => ({
    description: project.project.project.description || '',
    id: project.project.id,
    icons: project.project.project.technologies || [],
    image: project.project.project.thumbnail || false,
    title: project.project.title,
    link: project.project.uri,
  }));

  return (
    <Section key={section.id} subtitle={subtitle} title={title} variant={index % 2 === 0 ? 'primary' : 'secondary'} id={section.id}>
      <Grid>
        {items && items.map(item => (
          <Column width={[12, 6, 4, 4]} key={item.id}>
            <Card {...item} />
          </Column>
        ))}
      </Grid>
    </Section>
  )
};


const Page = ({ data, location }) => {
  const {
    content,
    sections: { sections },
    title,
  } = data.wp.page;

  // console.log(sections);

  const handlers = {
    'WP_Page_Sections_Sections_Contact': handleContact,
    'WP_Page_Sections_Sections_Featuredprojects': handleFeaturedProjects,
    'WP_Page_Sections_Sections_Imageblock': handleImageBlock,
    'WP_Page_Sections_Sections_Textblock': handleTextBlock,
  }

  return (
    <Layout location={location}>
      <SEO title={title}/>
      <Stack>
        {sections.length > 0 && sections.map((section, index) => {
          const handler = handlers[section.__typename];
          return handler ? handler(section, index) : null;
        })}

        {content && (
          <Section title={title}>
            <HtmlRenderer html={content} />
          </Section>
        )}
      </Stack>
    </Layout>
  );
};

export default Page;

Page.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string
      }),
    }),
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      }),
    }),
    name: PropTypes.string,
    slug: PropTypes.string,
    sponsorRole: PropTypes.string,
    twitter: PropTypes.string,
    whitePages: PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string
    }),
    website: PropTypes.string,
    youtube: PropTypes.string
  }),
};

Page.defaultProps = {
  data: {},
};

export const pageQuery = graphql`
    query GET_PAGE($id: ID!) {
        wp {
            page(id: $id) {
                title
                uri
                content
                sections {
                    sections {
                        __typename
                        ...ImageBlock
                        ...FeaturedProjects
                        ...Contact
                        ...TextBlock
                    }
                }
            }
        }
    }
`;
