import styled from '@emotion/styled';
import { Box } from 'rebass';
import { colors, space } from '../../styles/theme';

export const Wrapper = styled(Box)({
  textAlign: 'center',
  margin: `${space.base} 0`,
});

export const IconLink = styled('a')({
  color: colors.offblack,
  padding: space.small,
  transition: 'all 0.3s ease-in-out',

  'i': {
    fontSize: '2.8rem',
  },

  '&:hover': {
    color: colors.secondary,
  },
});