import styled from '@emotion/styled';
import Button from '../Button'
import { space } from '../../styles/theme';
import mediaQueries from "../../styles/mediaQueries";

export const BlockButton = styled(Button)({
  paddingBottom: 0,

  [mediaQueries.medium]: {
    paddingBottom: space.xxxlarge,
  },
});

export const Subtitle = styled('h3')({
  paddingBottom: space.xlarge,
});