import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Image = ({ img }) => (
  img && <Img fluid={img.imageFile.childImageSharp.fluid} alt={img.altText} />
);

Image.propTypes = {
  imageFile: PropTypes.object,
};

Image.defaultProps = {
  imageFile: {},
};


export default Image;